.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}
.menus>li{
  border-right: 1px solid #083f88;
}
.menus>li:last-child{
  border:none;
}
.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.images-wrap{
  transform: none !important;
  width: 100% !important;
}

.FHA203{
  padding: 2rem 0;
}

.presentationImg{
  width: 110%;
  cursor: pointer;
  margin-left: -2.5rem;
}

.presentationImgMob{
  width: 100%;
  cursor: pointer;
  padding-bottom: 1.5rem;
}

body {
  font-family: "Poppins" !important;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 30px;
}

.a-white {
  text-decoration: none;
  color: #fff;
}

.a-white:hover {
  text-decoration: underline;
  color: #fff;
}

.align-text {
  text-align: center;
}

.bodyContainer {
  position: relative;
}

.desktop-bg {
  display: block;
  z-index: -2;
  opacity: 50%;
  height: 35rem !important;
  width: 100% !important;
}

.mobile-bg {
  display: none;
  z-index: -2;
}

.body-bg {
  position: absolute;
  z-index: -2;
}

.dots-group {
  position: absolute;
  left: 1%;
  margin-top: 20%;
  max-width: 50px;
}

.text-justify {
  text-align: justify;
}

.wsj-business{
  float: right;
  margin-top: -15rem;
  margin-right: 2rem;
  position: relative;
  width: 42%;
}

.wsj-btn {
  position: absolute;
  background-color: #ffffff;
  border-radius: 25px;
  top: 95%;
  left: 58%;
  font-size: 10px;
}

.gallery-section-container {
  padding: 10px;
  /* padding-left: 0; */
  padding-right: 0;
}
.gallery-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  gap: 1rem;
  padding: 30px 0px;
}
.posts {
  width: 40%;
  height: 450px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.postimages {
  width: 600px;
  cursor: pointer;
}

.leftarrow, .rightarrow{
  cursor: pointer;
  margin: 0 0 2rem 0;
  z-index: 2;
  
}
.leftarrow{
  margin-left: 30%;

}
.rightarrow{
  margin-left: 30%;
  
}
.navbar1{
  background:transparent !important;
    z-index: 999;
    box-shadow:none;
    padding-top: 1em;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    justify-content: center !important;
}
.navbar1.active{ 
  background: radial-gradient(78.52% 125.63% at 41.41% -16.63%, #eeecec 0%, #eeecec 29.17%, #eeecec 57.29%, #eeecec 88%, #eeecec 100%) !important;
}


@media only screen and (min-width: 320px) and (max-width: 991px) {
  .gallery-section-container{
    gap: 2rem;
  }
  .postimages {
    width: 250px;
  }
  .posts {
    height: 120px;
  }
  .leftarrow, .rightarrow{
    display:none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 356px) {
  .postimages {
    width: 230px;
  }
}

@media only screen and (min-width: 357px) and (max-width: 599px) {
  .postimages {
    width: 270px;
  }

}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .postimages {
    width: 400px;
  }
  .posts {
    height: 300px;
  }
}
@media screen and (min-width: 768px) {
  .presentationImgMob{
    display: none;
  }
}
@media screen and (min-width: 1199.9px) {
  .presentationImg{
    margin-left: -3rem;
  }
}
@media screen and (max-width: 767.9px) {
  h1, h2, h3, h4, h5, h6 {
    line-height: 25px;
  }
  .presentationImg{
    display: none;
  }
  .myModal{
    top: 30%;
  }

  .desktop-bg,
  .dots-group {
    display: none;
  }

  .mobile-bg{
    display: block;
    width: 100%;
  }
}
@media (min-width: 991.9px) {
  .table-title.broker-videos-title {
    font-size: clamp(30px, calc(30px + 2vw), 45px);
    line-height: 1.5;
  }
}
@media screen and (min-width: 320px) and (max-width: 376px) {
  .wsj-business{
    margin-top: -5rem;
    position: absolute;
    width: 100%
  }
  .wsj-btn {
    top: 31.2% !important;
    left: 8% !important;
    font-size: 7px !important;
  }
}

@media screen and (min-width: 376px) and (max-width: 400px) {
  .mobile-bg {
    width: 400px;
  }
  .wsj-business{
    margin-top: -5rem;
    position: absolute;
    width: 100%
  }
}

@media screen and (min-width: 401px) and (max-width: 500px) {
  .mobile-bg {
    width: 500px;
  }
  .wsj-business{
    margin-top: -8rem;
    position: absolute;
    width: 100%
  }
}

@media screen and (min-width: 501px) and (max-width: 600px) {
  .mobile-bg {
    width: 600px;
  }
  .wsj-business{
    margin-top: -12rem;
    position: absolute;
    width: 100%
  }
}  

@media screen and (min-width: 601px) and (max-width: 700px) {
  .mobile-bg {
    width: 700px;
  }
  .dots-group {
    max-width: 50px;
  }
  .wsj-business{
    margin-top: -15rem;
    position: absolute;
    width: 100%
  }
}
@media screen and (min-width: 320px) and (max-width: 740px) {
  .modal-content{
    margin-top: 3.5rem;
  }
}
@media screen and (min-width: 701px) and (max-width: 767px) {
  .mobile-bg {
    width: 100%;
  }
  .wsj-business{
    margin-top: -18rem;
    position: absolute;
    width: 100%
  }
}

@media screen and (min-width: 768px) and (max-width: 794px) {
  
  .wsj-business{
    margin-top: -15rem;
    margin-right: 20rem;
    position: absolute;
    width: 50% 
    
  }
  .desktop-bg{
    height: 20rem !important;
  }
}

@media screen and (min-width: 794px) and (max-width: 991.9px) {
  
  .wsj-business{
    margin-top: -17rem;
    margin-right: 20rem;
    position: absolute;
    width: 50%;
  }
  .desktop-bg{
    height: 20rem !important;
    width: 100% !important;
  }
}
@media screen and (min-width: 940px) and (max-width: 991.9px) {
  
  .wsj-business{
    margin-top: -17.8rem;
    margin-right: 20rem;
    position: absolute;
    width: 50%;
  }

}

@media screen and (min-width: 992px) and (max-width: 1899.9px)   {
  .container-pad {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .desktop-bg {
    height: 600px !important;
  }
}

@media screen and (min-width: 1122.9px) and (max-width: 1171px) {
  .desktop-bg {
    height: 650px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1415.9px) {
  .desktop-bg {
    height: 600px !important;
  }
}

@media screen and (min-width: 1399.9px) and (max-width: 1415.9px) {
  .desktop-bg {
    height: 650px !important;
  }
}

@media screen and (min-width: 1416px) and (max-width: 1919.9px) {
  .desktop-bg {
    width: 100% !important;
    height: 650px !important;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2000px) {
  .desktop-bg {
    width: 2000px;
    height: 630px !important;
  }
  .dots-group {
    max-width: 80px;
  }
}

@media screen and (min-width: 2001px) and (max-width: 3000px) {
  .desktop-bg {
    width: 3000px;
    height: 630px !important;
  }
  .dots-group {
    max-width: 100px;
  }
}

@media screen and (min-width: 3001px) and (max-width: 4000px) {
  .desktop-bg {
    width: 4000px;
    height: 600px !important;
  }
  .dots-group {
    max-width: 100px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1000px){
  .wsj-business{
    margin-top: -11rem;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1240px){
  .wsj-business{
    margin-top: -10rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px){
  .wsj-business{
    margin-top: -12rem;
  }
}

@media screen and (min-width: 1500px)and (max-width: 2450px) {
  .wsj-business{
    margin-top: -18rem;
  }
}



@media screen and (min-width: 1900px)and (max-width: 2000px) {
  .wsj-business{
    margin-top: -22rem;
  }
}

@media screen and (min-width: 2000px)and (max-width: 2200px) {
  .wsj-business{
    margin-top: -26rem;
  }
}

@media screen and (min-width: 2200px)and (max-width: 2500px) {
  .wsj-business{
    margin-top: -30rem;
  }
}

@media screen and (min-width:1400px) {
  .navbar1{
      padding-right: 3em;
      padding-top: 1.5em;
  }
.navbar1.active{
  padding-top: 2em;  
  }
}

.navbar-brand {
  text-decoration: none;
  white-space: normal !important;
}